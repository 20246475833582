
let menuIcon = document.querySelector('.menu-icon');

menuIcon.addEventListener("click", function(){
  let navLinkContainer = document.querySelector('.mobile-nav-link-container');
  navLinkContainer.classList.toggle('show-mobile-nav');
});

import tippy from 'tippy.js';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/dist/svg-arrow.css';

tippy('[data-tippy-content]', {theme: 'light-border', trigger: "mouseenter click", allowHTML: true, arrow: true, interactive: true});